<template>
  <div class="mt-6">
    <vs-divider>Status Pembayaran</vs-divider>
    <div class="vx-row">
      <div class="vx-col sm:w-12/12 w-full">
        <vs-table :data="initData.payments" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap text-center">#</vs-th>
            <vs-th class="whitespace-no-wrap text-center">ID</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Tgl. Diajukan</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Jml Komisi</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Jml Bayar</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Jml Sisa</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Status Approval</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Approval Progress</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Status</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr class="text-sm" v-for="(item, index) in data" :key="item.uuid">
              <vs-td>{{ index + 1 }}</vs-td>
              <vs-td class="whitespace-no-wrap">#ID{{ item.id }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.tgl_diajukan }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.jml_komisi | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.jml_bayar | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.jml_sisa | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap">
                <span class="inline-flex items-center whitespace-no-wrap justify-center p-1 text-xs leading-none text-white rounded-full" :class="['bg-' + item.status_approval_color]">{{ item.status_approval }}</span>
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <span v-if="item.status_approval" class="text-xs underline cursor-pointer" @click="showModalApproval(item)">Lihat</span>
                <span v-else class="text-sm">-</span>
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <span class="inline-flex items-center whitespace-no-wrap justify-center p-1 text-xs leading-none text-white rounded-full" :class="['bg-' + item.status_color]">{{ item.status }}</span>
              </vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.keterangan }}</vs-td>
            </vs-tr>
            <vs-tr class="text-sm font-bold">
              <vs-td></vs-td>
              <vs-td></vs-td>
              <vs-td></vs-td>
              <vs-td class="text-right">{{ summary.grandTotalJmlKomisi | idr }}</vs-td>
              <vs-td class="text-right">{{ summary.grandTotalJmlBayar | idr }}</vs-td>
              <vs-td class="text-right">{{ summary.grandTotalJmlSisa | idr }}</vs-td>
              <vs-td></vs-td>
              <vs-td></vs-td>
              <vs-td></vs-td>
              <vs-td></vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <!--modals-->
    <ModalApprovalProgress :isActive.sync="modalApproval.active" :type="modalApproval.type" :id-ref="modalApproval.idRef"/>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'TabStatusPembayaran',
  props: {
    isActive: { type: Boolean },
    initData: { type: Object }
  },
  components: {
    ModalApprovalProgress: () => import('@/views/components/approval-progress/ModalApprovalProgress')
  },
  computed: {
    summary () {
      return {
        grandTotalJmlKomisi: _.sumBy(this.initData.payments, item => parseFloat(item.jml_komisi)),
        grandTotalJmlBayar: _.sumBy(this.initData.payments, item => parseFloat(item.jml_bayar)),
        grandTotalJmlSisa: _.sumBy(this.initData.payments, item => parseFloat(item.jml_sisa))
      }
    }
  },
  data () {
    return {
      modalApproval: {
        active: false,
        type: null,
        idRef: null
      }
    }
  },
  methods: {
    showModalApproval (item) {
      this.modalApproval.type = 'KOMISI'
      this.modalApproval.idRef = item.id
      this.modalApproval.active = true
    }
  }
}
</script>
